import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  styled,
  Paper,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0",
  height: "100vh",
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25%",
  height: "25%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px",
  borderRadius: "8px",
  padding: "2%",
  "@media (max-width: 600px)": {
    width: "90%",
    height: "70%",
  },
});

const CustomButton = styled(Button)({
  marginTop: "5%",
});

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
};

export const PasswordResetScreen = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const history = useHistory();

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    setIsValidEmail(true);
  };

  const handleResetClick = () => {
    if (!isValidEmail) {
      return; // Don't proceed if email is not valid
    }

    if (email) {
      getApiService()
        .postTriggerPasswordReset(email, navigator.language)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            history.push("/password-reset-done");
          }
        })
        .catch(() => {
          history.push("/server-error");
        });
    }
  };

  const handleBlur = () => {
    setIsValidEmail(validateEmail(email));
  };

  return (
    <CustomPaper elevation={2}>
      <CustomBox>
        <Typography variant="h4" gutterBottom>
          Password Reset
        </Typography>
        <Typography variant="body1" paragraph>
          Enter your email address to reset your password.
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          onBlur={handleBlur}
          error={!isValidEmail}
          helperText={!isValidEmail && "Invalid email format"}
        />
        <CustomButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleResetClick}
        >
          Reset Password
        </CustomButton>
      </CustomBox>
    </CustomPaper>
  );
};
