import { init } from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { setEnvVariablesToGlobalState } from "shared/src/providers/Environment";
import { App } from "./App";
import "./index.scss";

setEnvVariablesToGlobalState();
// LOAD SENTRY ON GIVEN KEY
if (window.env.SENTRY_DSN)
  init({
    dsn: window.env.SENTRY_DSN,
    environment: window.env.SENTRY_ENVIRONMENT,
  });

ReactDOM.render(<App />, document.querySelector("#root"));
