import React, { useEffect, useState } from "react";
import { Datagrid, List, Loading, TextField } from "react-admin";
import { getPartnerPluginSdkUrlInSessionStorage } from "shared/src/utils/storage-variables";
import Select from "react-select";
import styled from "@emotion/styled";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { getApiService } from "shared/src/utils/api-service";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ComponentProps } from "../../providers/Interfaces";

const CustomWrapper = styled.div({
  backgroundColor: "#ffffff",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  padding: "16px",
  borderRadius: "4px",
});

const Title = styled(Typography)({
  fontSize: "1.2rem",
  marginBottom: "2%",
});
const Text = styled(Typography)({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
});
const CustomSelect = styled(Select)({
  width: "30%",
  marginTop: "2%",
  marginBottom: "2%",
});
const CustomOrderList = styled.ol({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  lineHeight: "2",
});
const CustomUnorderedList = styled.ul({
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  lineHeight: "2",
});

export const PartnerList = (props: ComponentProps): JSX.Element => {
  const { permissions } = props;
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<string[]>([]);

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }

    const fetchAndSetBrands = async () => {
      if (!brands.length) {
        const res = await getApiService().fetchBrands();
        // Filter out non-string values
        const stringBrands = res.filter((item) => typeof item === "string");
        setBrands(stringBrands);
      }
    };

    fetchAndSetBrands();
  }, [permissions, brands.length]);

  const sdkUrl = getPartnerPluginSdkUrlInSessionStorage();

  const code = `<!DOCTYPEhtml>
  <html>

  <head>
    <title>Product Detail Page</title>
  </head>

  <body>
    <input type='text' id='brand' name='brand' value='Nike' style='display: none;'>
    <input type='text' id='category' name='category' value='sneaker' style='display: none;'>

    <button onclick='toggleOnefidPlugin()'> Get size recommendation </button>

    <script src='${sdkUrl}onefid-sdk.js'></script>

    <script>loadOnefidPlugin({
        simpleRecommendation: true,
        brand: document.getElementById('brand').value,
        category: document.getElementById('category').value,
      });
    </script>

  </body>

  </html>`;

  if (loading) return <Loading />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List {...props} pagination={false} exporter={false} title="Domain">
          <Datagrid rowClick="edit">
            <TextField label="Domain" source="website_url" />
          </Datagrid>
        </List>
      </Grid>
      <Grid item xs={12}>
        <CustomWrapper>
          <Title variant="h4">Integration</Title>
          <CustomOrderList>
            <li>
              call OneFID Plugin SDK
              <Typography variant="h6" style={{ fontStyle: "italic" }}>
                {sdkUrl}
              </Typography>
            </li>
            <li>load OneFID Plugin with loadOnefidPlugin()</li>
            <li>assign brand and category of product</li>
            <li>toggle OneFID Plugin with button with toggleOnefidPlugin()</li>
          </CustomOrderList>
          <Title variant="h6">Example:</Title>
          <SyntaxHighlighter
            language="javascript"
            style={vscDarkPlus}
            customStyle={{ overflowY: "auto" }}
          >
            {code}
          </SyntaxHighlighter>
          <Title variant="h6">Brands and categories:</Title>

          <Title>
            For the correct size recommendation of the products, only the
            assignment to brand and shoe category is required.
            <br />
            Currently, the following brands are available. Feel free to contact
            us to add more brands.
          </Title>
          <CustomSelect
            options={brands.map((brand) => ({ value: brand, label: brand }))}
          />

          <Text>
            Specifying the appropriate shoe category ensures the correct fit.
            <br />
            The respective product must be assigned to an appropriate category.
            The most important categories are:
            <br />
            <br />
          </Text>
          <CustomUnorderedList>
            <li>Low shoes = &quot;low-shoes&quot;</li>
            <li>Sneakers = &quot;sneaker&quot;</li>
            <li>Pumps = &quot;pumps&quot;</li>
            <li>Running shoes = &quot;running-shoes&quot;</li>
            <li>Soccer shoes = &quot;soccer-shoes&quot;</li>
            <li>Indoor shoes = &quot;indoor-multisport&quot;</li>
            <li>Outdoor shoes = &quot;outdoor-shoes&quot;</li>
            <li>Work shoes = &quot;low-work-shoes&quot;</li>
          </CustomUnorderedList>
          <Text>
            An overview of all categories can be viewed at our &nbsp;
            <a
              href="https://core.onefid.com/api/v2/categories/"
              target="_blank"
              rel="noopener noreferrer"
            >
              categories list
            </a>
          </Text>
        </CustomWrapper>
      </Grid>
    </Grid>
  );
};
