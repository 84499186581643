/* eslint-disable camelcase */
import styled from "@emotion/styled";
import { Box, Paper, Typography, Button } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import { Loading } from "react-admin";
import { getApiService } from "shared/src/utils/api-service";
import { unparse as convertToCSV } from "papaparse";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { noImage } from "../../assets";
import { FavouriteShoe } from "./UserShow";
import { UserApi } from "../../providers/Interfaces";

type ImageContainer = {
  [key: string]: string;
  foot_view: string;
  image: string;
};
const CustomPaper = styled(Paper)({
  display: "flex",
  alignItems: "center",
  marginLeft: "1em",
  minWidth: "50%",
  flexWrap: "wrap",
});

const TextWrapper = styled.div({
  width: "50%",
  maxWidth: "50%",
});

const LoadingWrapper = styled.div({
  width: "50%",
  height: "80vh",
});

const ButtonWrapper = styled.div({
  width: "100%",
  height: "50px",
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  paddingBottom: "10px",
});

const CustomImage = styled.img({
  width: "40%",
  marginBottom: 10,
});

const CustomButton = styled(Button)({
  backgroundColor: "#2196f3",
  "&:hover": {
    backgroundColor: "#2196f3",
  },
});

type AsideUserFootProps = {
  record?: UserApi;
};

export const AsideUserFoot = (props: AsideUserFootProps): ReactElement => {
  const { record } = props;
  const [footMeasurements, setFootMeasurementsTo] = useState<{
    [key: string]: string;
  }>({});
  const [favouriteShoe, setFavouriteShoeTo] = useState<{
    [key: string]: string;
  }>({});

  const [loading, setLoading] = useState(true);
  const imagesToShow = ["top_left", "top_right", "medial_left", "medial_right"];

  useEffect(() => {
    // TODO: Add error handling, e.g. 404 not found (see sentry)
    const getMeasurements = async (userId: string) => {
      const measurements = await getApiService().fetchUserManualMeasurements(
        userId
      );
      setFootMeasurementsTo(measurements);
      setLoading(false);
    };
    const getFavouriteShoe = async (userId: string) => {
      const favouriteShoeResponse: FavouriteShoe =
        await getApiService().fetchUserFavouriteShoe(userId);
      if (
        Object.values(favouriteShoeResponse).every(
          (value) => value !== null || value !== ""
        )
      ) {
        setFavouriteShoeTo(favouriteShoeResponse);
      }
    };

    if (record) {
      const userId = record.email ? record.email : record.employee_number;
      getMeasurements(userId);
      getFavouriteShoe(userId);
    }
  }, [record]);

  if (!record || !record?.foot_images) return <></>;
  if (loading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }
  const footImages: Map<string, string> = new Map();
  record.foot_images.map((imageContainer: ImageContainer) =>
    footImages.set(imageContainer.foot_view, imageContainer.image)
  );

  const measurementsToExport = [
    "ball_width_manual_left",
    "ball_width_manual_right",
    "foot_length_manual_left",
    "foot_length_manual_right",
  ];
  const favouriteShoeData = ["brand", "category", "size"];
  const folderName: string = record.email.replace(
    /[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~-]/gi,
    "_"
  );
  const downloadUserData = () => {
    const zip = new JSZip();
    const folder = zip.folder("collection");
    const data = measurementsToExport.map((key: string) =>
      footMeasurements[key] ? footMeasurements[key] : ""
    );
    favouriteShoeData.map((key: string) =>
      data.push(favouriteShoe[key] ? favouriteShoe[key] : "")
    );
    if (data.some((value) => value !== "")) {
      const csv = convertToCSV({
        data,
        fields: [
          "Left Width",
          "Right Width",
          "Left Length",
          "Right Length",
          "brand",
          "category",
          "size",
        ],
      });
      folder.file("measurements.csv", csv);
    }
    Promise.resolve(
      imagesToShow.map(async (key: string) => {
        const imageUrl: string | undefined = footImages.get(key);
        if (imageUrl) {
          const imageBlob = await getApiService().fetchUserImage(imageUrl);
          const imageFile = new File([imageBlob], `${key}.jpg`);
          folder.file(`${key}.jpg`, imageFile);
        }
      })
    ).then(() => {
      folder
        .generateAsync({
          type: "blob",
        })
        .then((content: Blob | string) => saveAs(content, folderName));
    });
  };

  return (
    <CustomPaper elevation={2} style={{ maxWidth: "70%" }}>
      <Box
        display="flex"
        alignItems="center"
        margin="1em 1em"
        textAlign="center"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        width="-webkit-fill-available"
      >
        {footImages.size > 0 ? (
          <>
            {["left", "right"].map((value) => (
              <TextWrapper>
                <Typography
                  style={{ textTransform: "capitalize" }}
                >{`${value} foot`}</Typography>
                <Typography>
                  Length: {footMeasurements[`foot_length_manual_${value}`]}
                </Typography>
                <Typography>
                  Width: {footMeasurements[`ball_width_manual_${value}`]}
                </Typography>
              </TextWrapper>
            ))}
            {imagesToShow.map((key: string) =>
              footImages.get(key) ? (
                <CustomImage
                  src={footImages.get(key) || noImage}
                  alt={key}
                  key={key}
                  // Chrome has a bug/"feature" that's been present since at least Aug 2014 that causes a cross-origin request to fail if the resource was first loaded via a normal fetch, apparently because Chrome caches the CORS-less resource headers and then refuses to give the cached resource to the cross-origin request.
                  // See: https://stackoverflow.com/questions/20253472/cors-problems-with-amazon-s3-on-the-latest-chomium-and-google-canary/42097546#42097546
                  // And: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
                  // Adding below tag to force Chrome to pull the CORS headers in
                  crossOrigin="anonymous"
                />
              ) : (
                <CustomImage src={noImage} alt={key} />
              )
            )}
          </>
        ) : (
          <p>No images uploaded</p>
        )}
      </Box>
      <ButtonWrapper>
        <CustomButton
          color="primary"
          variant="contained"
          onClick={() => downloadUserData()}
          disabled={footImages.size < 2 && true}
        >
          Download User Data
        </CustomButton>
      </ButtonWrapper>
    </CustomPaper>
  );
};
