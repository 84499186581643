import styled from "@emotion/styled";
import { Box, Paper } from "@material-ui/core";
import React, { ReactElement } from "react";
import {
  Datagrid,
  TextField,
  Tab,
  TabbedShowLayout,
  ArrayField,
} from "react-admin";
import { OrderApi } from "../../providers/Interfaces";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  marginLeft: "1em",
  minWidth: "50%",
});

const CustomTabbedShowLayout = styled(TabbedShowLayout)({
  ".flex-tab": {
    display: "flex",
  },
});

type AsideTabsProps = {
  record?: OrderApi;
};

export const AsideTabs = (props: AsideTabsProps): ReactElement => {
  const { record } = props;
  // Do not render aside component if certain values from the details endpoint are not yet populated.
  if (!record || !record?.brand) return <></>;

  return (
    <CustomPaper elevation={2}>
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        alignItems="center"
        // maxWidth={300}
        margin="1em 2.5em"
      >
        <CustomTabbedShowLayout record={record} {...props}>
          <Tab label="description" contentClassName="flex-tab">
            <TextField
              addLabel="German"
              source="description_de"
              component="pre"
            />
            <TextField
              addLabel="English"
              source="description_en"
              component="pre"
            />
          </Tab>
          <Tab label="sizes" path="sizes">
            <ArrayField source="details" addLabel={false}>
              <Datagrid>
                <TextField source="gtin" sortable={false} />
                <TextField source="eu" sortable={false} />
                <TextField source="uk" sortable={false} />
                <TextField source="us" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </CustomTabbedShowLayout>
      </Box>
    </CustomPaper>
  );
};
