import React, { ReactElement, cloneElement, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  Filter,
  FunctionField,
  List,
  Pagination,
  Responsive,
  SelectField,
  SelectInput,
  SimpleList,
  TextField,
  TopToolbar,
  ExportButton,
} from "react-admin";
import { getApiService } from "shared/src/utils/api-service";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { TextInput } from "../../components/TextInput";
import { ComponentProps, OrderApi } from "../../providers/Interfaces";
import {
  displayUserIdentifier,
  exportToExcel,
  nestedDictHasValueForKey,
} from "../../utils/Helper";
import { BulkPrintQR } from "./BulkActions/BulkPrintQr";
import { BulkUpdateStatus } from "./BulkActions/BulkUpdateStatus";
import { orderStatusChoices } from "./OrderEdit";
import { FileUploadButton } from "./FileUploadButton";

const fields = [
  "industry_partner",
  "site",
  "email",
  "employee_number",
  "gtin",
  "is_customized",
  "status",
  "created_at",
];

const exportOrdersInExcel = (orders: any) => {
  const filteredFields = fields.filter((field: string) =>
    nestedDictHasValueForKey({ ...orders }, field)
  );

  exportToExcel(orders, filteredFields, "Orders");
};

const OrdersPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const OrdersBulkActionButtons = (props: any) => (
  <>
    <BulkPrintQR {...props} />
    <BulkUpdateStatus {...props} />
  </>
);

const OrdersFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="search" label="Customer" resettable alwaysOn />
      <TextInput source="industry_partner" label="Partner" resettable />
      <TextInput source="site" resettable />
      <BooleanInput
        source="is_customized"
        label="Customized"
        defaultValue="true"
      />
      <SelectInput source="status" choices={orderStatusChoices} />
      <DateInput source="created_at" />
    </Filter>
  );
};

const getMessage = (errors: any) => {
  if (errors && errors.length > 0) {
    const errorMessages = errors.map((error: any) => {
      // eslint-disable-next-line camelcase
      const lineNumber = error.line_number || "";
      const errorKeys = Object.keys(error).filter(
        (key) => key !== "line_number"
      );
      const errorMessageList = errorKeys.map((key) => error[key][0]);
      const errorMessage = errorMessageList.join(", ");

      return (
        <DialogContentText>
          Error at line <strong>{lineNumber}</strong>:{" "}
          <strong>{errorMessage}</strong>
        </DialogContentText>
      );
    });

    return errorMessages;
  }

  return null;
};

export const OrdersList = (props: ComponentProps): ReactElement => {
  const [uploadingOrders, setUploadingOrders] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadErrors, setUploadErrors] = useState<[]>();

  const handleCloseResponseDialog = () => {
    setUploadDialogOpen(false);
  };

  const handleUpload = async (file: string) => {
    setUploadingOrders(true);
    try {
      const response = await getApiService().uploadOrders(file);
      setUploadMessage(response.message);
      if (response.errors) setUploadErrors(response.errors);
      setUploadDialogOpen(true);
    } catch (error) {
      setUploadMessage(`${error}`);
      setUploadDialogOpen(true);
    } finally {
      setUploadingOrders(false);
    }
  };
  // eslint-disable-next-line no-shadow
  const ListActions = (props: any) => (
    <TopToolbar>
      <FileUploadButton onFileUpload={handleUpload} loading={uploadingOrders} />
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {cloneElement(props.filters, { context: "button" })}
      {/* this is to add filter button */}
      <ExportButton exporter={exportOrdersInExcel} />
    </TopToolbar>
  );

  return (
    <>
      <List
        {...props}
        bulkActionButtons={<OrdersBulkActionButtons {...props} />}
        perPage={25}
        actions={<ListActions />}
        sort={{ field: "created_at", order: "DSC" }}
        title="Orders"
        pagination={<OrdersPagination />}
        filters={<OrdersFilter />}
      >
        <Responsive
          medium={
            <Datagrid rowClick="edit">
              <TextField source="industry_partner" label="Partner" />
              <TextField source="site" />
              <FunctionField
                label="Customer"
                render={(record: OrderApi) => displayUserIdentifier(record)}
              />
              <TextField source="gtin" />
              <BooleanField source="is_customized" label="Customized" />
              <SelectField source="status" choices={orderStatusChoices} />
              <DateField
                source="created_at"
                locales="en-DE"
                options={{
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                }}
                showTime
              />
              <EditButton />
            </Datagrid>
          }
          small={
            <SimpleList
              primaryText={(record: OrderApi) => displayUserIdentifier(record)}
              secondaryText={(record: OrderApi) => record.gtin}
              tertiaryText={(record: OrderApi) => record.status}
            />
          }
        />
      </List>
      <Dialog open={uploadDialogOpen} onClose={handleCloseResponseDialog}>
        <DialogTitle>Upload Result</DialogTitle>
        <DialogContent>
          <DialogContentText>{uploadMessage}</DialogContentText>
          <DialogContentText>
            {uploadErrors && `Errors happened: ${uploadErrors.length}`}
          </DialogContentText>
          {getMessage(uploadErrors)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResponseDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
