import React, { ReactElement, useEffect, useState } from "react";
import { Loading, Create, SimpleForm, TextInput, regex } from "react-admin";
import { Permissions } from "../../providers/Interfaces";

export const PartnerCreate = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}): ReactElement => {
  const [loading, setLoading] = useState(true);
  const urlRegex =
    /^(https?:\/\/)([\dA-Za-z-]+\.)+[A-Za-z]{2,}(\.[A-Za-z]{2,})?$/;
  const validateUrl = regex(
    urlRegex,
    "must be a valid url like https://www.example.com or https://www.sub.example.com"
  );

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [props, permissions]);

  if (loading) return <Loading />;

  return (
    <Create title="Add Domain" {...props}>
      <SimpleForm>
        <TextInput
          label="Enter domain"
          source="website_url"
          validate={validateUrl}
        />
      </SimpleForm>
    </Create>
  );
};
