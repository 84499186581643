import React, { ReactElement } from "react";
import {
  Datagrid,
  ImageField,
  List,
  Responsive,
  SimpleList,
  TextField,
  useListController,
} from "react-admin";
import { ComponentProps, ProductApi } from "../../providers/Interfaces";
import { nestedDictHasValueForKey } from "../../utils/Helper";

export const ProductsList = (props: ComponentProps): ReactElement => {
  const controllerProps = useListController(props);
  const { data } = controllerProps;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      title="Products"
    >
      <Responsive
        medium={
          <Datagrid rowClick="show">
            <ImageField
              source="images"
              src="original"
              label="Product Image"
              sortable={false}
            />
            <TextField source="brand" />
            <TextField source="name" />
            <TextField source="mpn" label="MPN" />
            {nestedDictHasValueForKey(data, "width_name") && (
              <TextField label="Width" source="width_name" />
            )}
            {nestedDictHasValueForKey(data, "sort_order") && (
              <TextField source="sort_order" />
            )}
          </Datagrid>
        }
        small={
          <SimpleList
            primaryText={(record: ProductApi) => record.name}
            secondaryText={(record: ProductApi) => record.brand}
            tertiaryText={(record: ProductApi) => record.mpn}
          />
        }
      />
    </List>
  );
};
