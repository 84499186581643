import React, { useEffect, useState } from "react";
import { useLogin } from "react-admin";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Paper,
  styled,
} from "@material-ui/core";
import logo from "../../utils/logo.png";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0",
  height: "100vh",
  padding: "16px",
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25%",
  height: "45%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px",
  borderRadius: "8px",
  padding: "2%",
  "@media (max-width: 600px)": {
    width: "90%",
    height: "70%",
  },
});

const Form = styled("form")({
  width: "100%",
  marginTop: "3%",
});

const SubmitButton = styled(Button)({
  margin: "3% 0",
});

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const login = useLogin();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await login({ email, password });
    } catch (error: any) {
      if (error[0]) {
        setErrorMessage(error[0]);
      } else if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          "You do not have enough permissions, please contact info@onefid.com"
        );
      }

      return;
    }

    if (rememberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("rememberedEmail", email);
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("rememberedEmail");
    }
  };

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (rememberMeValue === "true") {
      setRememberMe(true);
      const rememberedEmail = localStorage.getItem("rememberedEmail");
      setEmail(rememberedEmail || "");
    }
  }, []);

  return (
    <CustomPaper>
      <CustomBox>
        <img
          style={{ width: "106px", height: "106px" }}
          src={logo}
          alt="OneFID logo"
        />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
            label="Remember email"
          />
          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Login
          </SubmitButton>
          <Grid container>
            <Grid item xs>
              <Link href="/password-reset" variant="body2">
                Reset password
              </Link>
            </Grid>
          </Grid>
        </Form>
        {errorMessage && (
          <Typography
            style={{ color: "red", marginBottom: "10px", textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}
      </CustomBox>

      <Box mt={8} style={{ marginTop: "5%" }}>
        <Typography variant="body2" color="textSecondary" align="center">
          &copy; OneFID GmbH. All rights reserved.
        </Typography>
      </Box>
    </CustomPaper>
  );
};
