/* eslint-disable import/no-default-export */
import { getApiService } from "shared/src/utils/api-service";
import {
  getUserTokenFromStorage,
  removeItemFromStorage,
  getPermissionsFromSessionStorage,
  setPermissionsInSessionStorage,
  setPartnerNameInSessionStorage,
  setPartnerPluginSdkUrlInSessionStorage,
} from "shared/src/utils/storage-variables";
import { Permissions } from "./Interfaces";

type LoginProps = {
  email: string;
  username: string;
  password: string;
};

type Error = {
  status: number;
};

export default {
  login: async (props: LoginProps): Promise<void> => {
    const { email, username, password } = props;

    await getApiService().fetchLogin(email || username, password);
    const user = await getApiService().fetchUserProfile();
    const permissions: Permissions = Object.keys(user).filter(
      (key: string) => key.startsWith("partner_") && user[key] === true
    );
    if (!permissions.length) {
      throw new Error(
        "You do not have enough permissions to login, please contact info@onefid.com"
      );
    }

    setPermissionsInSessionStorage(permissions);
    setPartnerNameInSessionStorage(user.partner_name);
    setPartnerPluginSdkUrlInSessionStorage(user.partner_commerce_plugin_url);
  },
  logout: (): Promise<void | false | string> => {
    removeItemFromStorage("userToken");
    removeItemFromStorage("permissions");
    removeItemFromStorage("partnerName");
    removeItemFromStorage("sdkUrl");

    return Promise.resolve();
  },
  checkError: (error: Error): Promise<void> => {
    const { status } = error;
    if (status === 403) {
      return Promise.resolve();
    }

    if (status === 401) {
      removeItemFromStorage("userToken");
      removeItemFromStorage("permissions");
      throw new Error("Session expired. Please log in again.");
    }

    return Promise.resolve();
  },
  checkAuth: (): Promise<void> => {
    return getUserTokenFromStorage() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: (): Promise<any> => {
    const permissions = getPermissionsFromSessionStorage();

    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
};
