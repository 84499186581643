import React, { ReactElement } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  ArrayField,
  Datagrid,
  DateField,
} from "react-admin";
import { Permissions } from "../../providers/Interfaces";

export const CartsOverviewShow = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}): ReactElement => {
  return (
    <Show {...props} resource="carts">
      <SimpleShowLayout>
        <FunctionField
          label="Name"
          render={(rec: any) =>
            rec &&
            `${rec.personal_info.first_name} ${rec.personal_info.last_name}`
          }
        />
        <FunctionField
          label="Birthdate"
          render={(rec: any) => rec && rec.personal_info.birth_date}
        />
        <TextField label="Industry Partner" source="industry_partner" />
        <TextField label="Employee Nr." source="employee_number" />
        <TextField label="Store" source="store" />
        <TextField label="Order Nr." source="order_nr" />
        <DateField
          source="created_at"
          locales="en-DE"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          }}
          showTime
        />
        <ArrayField source="cart_items">
          <Datagrid>
            <TextField source="pos_nr" label="Position Number" />
            <TextField source="appliances_id" label="Appliance ID" />
            <TextField source="type" label="Type" />
            <TextField
              source="additional_product_gtin"
              label="Additional GTIN"
            />
            <TextField source="gtin" label="GTIN" />
            <TextField source="insole_set" label="Insole Set" />
            <TextField source="cart" label="Cart ID" />
            <TextField source="comments" label="Comments" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
