import React, { ReactElement } from "react";
import { FormDataConsumer } from "react-admin";
import { OrderApi, UserApi } from "../providers/Interfaces";
import { TextInput } from "./TextInput";

type Prop = {
  [key: string]: string | boolean | number;
};

type FormProps = {
  disabled: boolean;
  formData: OrderApi | UserApi;
};

export const CustomerInput = (props: Prop): ReactElement => (
  <FormDataConsumer {...props}>
    {(formProps: FormProps) => {
      const { disabled, formData, ...rest } = formProps;

      return formData.employee_number ? (
        <TextInput
          label="Customer"
          source="employee_number"
          disabled={disabled}
          {...rest}
        />
      ) : (
        <TextInput
          label="Customer"
          source="email"
          disabled={disabled}
          {...rest}
        />
      );
    }}
  </FormDataConsumer>
);
