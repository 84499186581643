/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 *
 * @param file - Image file.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", reject);

    reader.readAsDataURL(file.rawFile);
  });

export const addUploadFeature =
  (dataProvider: any) =>
  (type: string, resource: string, params: any): unknown => {
    const img = params?.data?.image;
    if ((type === "UPDATE" || type === "CREATE") && img) {
      const uploadedImage =
        img.rawFile instanceof File ? convertFileToBase64(img) : img;

      return Promise.resolve(uploadedImage).then((base64Image) => {
        return dataProvider(type, resource, {
          ...params,
          data: {
            ...params.data,
            image: base64Image,
          },
        });
      });
    }

    // for other request types and resources, fall back to the default data provider
    return dataProvider(type, resource, params);
  };
