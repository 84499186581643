import styled from "@emotion/styled";
import { Grid, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import {
  BooleanInput,
  DateInput,
  Edit,
  FormDataConsumer,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useEditController,
} from "react-admin";
import { CustomerInput } from "../../components/CustomerInput";
import { TextInput } from "../../components/TextInput";
import {
  ComponentProps,
  FormProps,
  OrderApi,
} from "../../providers/Interfaces";
import { displayUserIdentifier } from "../../utils/Helper";
import { MemoizedAsideQR as AsideQR } from "./AsideQr";

export const orderStatusChoices = [
  { id: "ordered-by-end-customer", name: "Ordered" },
  { id: "released-by-industry-customer", name: "Released" },
  { id: "released-by-partner", name: "Released for production" },
  { id: "received-by-desma", name: "Received by desma" },
  { id: "scheduled-for-production", name: "Scheduled for production" },
  { id: "in-production", name: "In production" },
  { id: "quality-control", name: "Quality control performed" },
  { id: "produced", name: "Produced" },
  { id: "reproduction", name: "In reproduction" },
  { id: "shipped", name: "Shipped" },
  { id: "canceled", name: "Canceled" },
];

const hardnessChoices = [
  { id: 0, name: "0" },
  { id: 2, name: "2" },
  { id: 4, name: "4" },
  { id: 6, name: "6" },
  { id: 8, name: "8" },
  { id: 10, name: "10" },
];

export type Props = {
  record?: OrderApi;
};

const OrderTitle = ({ record }: Props) => (
  <span>{`Order for ${record && displayUserIdentifier(record)}`}</span>
);

const OrderEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ConditionalTextInput = (props: any) => {
  const { record, source, disabled, label, ...rest } = props;
  if (!record || !record[source]) return <></>;

  return (
    <TextInput
      // Use unofficial value attribute instead of source attribute to directly access the value via the record prop
      value={record[source]}
      label={label}
      disabled={disabled}
      {...rest}
    />
  );
};

// Do not rerender component if record.oid is overwritten by undefined. For some reason the record is sometimes overwritten by the list endpoint where in contrast to the detail endpoint these keys are not present and values are accordingly undefined.
const MemoizedConditionalTextInput = React.memo(
  ConditionalTextInput,
  (prevProps, nextProps) => {
    if (prevProps.record?.oid && !nextProps.record?.oid) return true;

    return false;
  }
);

const CustomSimpleForm = styled(({ children, ...props }) => (
  <SimpleForm {...props}>{children}</SimpleForm>
))({
  "&.simple-form > .MuiCardContent-root > .ra-input > .MuiGrid-container": {
    width: "calc(100% + 8px)",
    "& > .MuiGrid-item > .MuiFormControl-root": {
      width: "254px",
    },
  },
});

export const OrderEdit = (props: ComponentProps): ReactElement => {
  const controllerProps = useEditController(props);

  return (
    <Edit
      title={<OrderTitle />}
      undoable={false}
      aside={<AsideQR />}
      {...props}
    >
      <CustomSimpleForm redirect={false} toolbar={<OrderEditToolbar />}>
        <Grid container>
          <Grid item lg={5} md={5}>
            <TextInput source="industry_partner" label="Partner" disabled />
            <TextInput source="site" />
            <MemoizedConditionalTextInput
              source="oid"
              label="Order ID"
              disabled
            />
            <MemoizedConditionalTextInput
              source="olid"
              label="Order Line ID"
              disabled
            />
            <CustomerInput disabled />
            <TextInput source="gtin" disabled />
            <BooleanInput
              source="is_customized"
              label="Customized"
              options={{ disabled: true }}
            />
            <SelectInput source="status" choices={orderStatusChoices} />
            <DateInput source="created_at" disabled />
          </Grid>

          {controllerProps.record?.status !== "ordered-by-end-customer" && (
            <FormDataConsumer {...controllerProps} {...props}>
              {(formProps: FormProps) => {
                const { record, formData } = formProps;
                const isDisabledWhenStatusNotReleased =
                  record?.status !== "released-by-industry-customer" ||
                  (record?.status !== "released-by-industry-customer" &&
                    formData.status !== "released-by-partner");

                return (
                  <Grid
                    container
                    item
                    lg={7}
                    md={12}
                    alignItems="flex-start"
                    style={{ height: "542.5px", paddingRight: "40px" }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      justify="center"
                      style={{ alignSelf: "flex-end" }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Hardness Information (in %)
                      </Typography>
                    </Grid>
                    <Grid container item md={6} xs={7} justify="center">
                      <SelectInput
                        source="left_forefoot_hardness_in_percent"
                        label="Left Forefoot"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                      <SelectInput
                        source="left_midfoot_hardness_in_percent"
                        label="Left Midfoot"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                      <SelectInput
                        source="left_heel_hardness_in_percent"
                        label="Left Heel"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                    </Grid>
                    <Grid container item md={6} xs={7} justify="center">
                      <SelectInput
                        source="right_forefoot_hardness_in_percent"
                        label="Right Forefoot"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                      <SelectInput
                        source="right_midfoot_hardness_in_percent"
                        label="Right Midfoot"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                      <SelectInput
                        source="right_heel_hardness_in_percent"
                        label="Right Heel"
                        choices={hardnessChoices}
                        options={{
                          disabled: isDisabledWhenStatusNotReleased,
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            </FormDataConsumer>
          )}
        </Grid>
      </CustomSimpleForm>
    </Edit>
  );
};
