import { Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import {
  DateField,
  FunctionField,
  Loading,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
  ArrayField,
  Datagrid,
} from "react-admin";
import { getApiService } from "shared/src/utils/api-service";
import { Permissions, UserApi } from "../../providers/Interfaces";
import { displayUserIdentifier, splitAndCapitalize } from "../../utils/Helper";
import { AsideUserFoot } from "./AsideUserFoot";
import { ScanMeasurements } from "./ScanMeasurements";

export type FavouriteShoe = {
  [key: string]: string;

  brand: string;
  category: string;
  size: string;
};

type Record = {
  basePath: string;
  id: string;
  record: {
    id: number;
    scandate: string;
    files: [{ side: string; id: number }];
  };
  resource: string;
};

const UserTitle = (props: any) => {
  const { record } = props;

  return <span>{`User ${displayUserIdentifier(record)}`}</span>;
};

export const UserShow = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}): ReactElement => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const [loading, setLoading] = useState(true);
  const [isFavouriteShoeEmpty, setIsFavouriteShoeEmpty] = useState(false);
  const [favouriteShoe, setFavouriteShoeTo] = useState<{
    [key: string]: string;
  }>({});
  const { brand, category, size } = favouriteShoe;

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [props, permissions]);

  useEffect(() => {
    // TODO: Add error handling, e.g. 404 not found (see sentry)
    const getFavouriteShoe = async (email: string) => {
      const favouriteShoeResponse: FavouriteShoe =
        await getApiService().fetchUserFavouriteShoe(email);
      if (
        Object.values(favouriteShoeResponse).some(
          (value) => value === null || value === ""
        )
      ) {
        setLoading(false);
        setIsFavouriteShoeEmpty(true);
      } else {
        setLoading(false);
        setFavouriteShoeTo(favouriteShoeResponse);
      }
    };
    if (record && record.email) {
      getFavouriteShoe(record.email);
    }
  }, [record]);

  if (loading) return <Loading />;

  return (
    <Show
      title={<UserTitle />}
      aside={
        permissions.includes("partner_show_foot_images") && <AsideUserFoot />
      }
      {...props}
    >
      <SimpleShowLayout>
        <FunctionField
          label="Customer"
          render={(rec: UserApi) => displayUserIdentifier(rec)}
        />
        {record && record.first_name && <TextField source="first_name" />}
        {record && record.last_name && <TextField source="last_name" />}
        {record && record.foot_length_manual && (
          <TextField label="Foot length" source="foot_length_manual" />
        )}
        {record && record.ball_width_manual && (
          <TextField label="Ball width" source="ball_width_manual" />
        )}
        <FunctionField
          label="Gender"
          render={(rec: UserApi) => (rec.gender === "m" ? "Male" : "Female")}
        />
        <FunctionField
          label="Continent"
          render={(rec: UserApi) => splitAndCapitalize(rec.continent)}
        />
        <TextField label="Device" source="device" />
        <TextField label="Device Site" source="device_site" />
        <DateField
          source="created_at"
          locales="en-DE"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          }}
          showTime
        />
        {permissions.includes("partner_show_foot_images") &&
          (isFavouriteShoeEmpty ? (
            <Typography>Favorite shoe is not selected.</Typography>
          ) : (
            [
              <FunctionField label="Brand" disabled render={() => brand} />,
              <FunctionField
                label="Category"
                disabled
                render={() => category}
              />,
              <FunctionField label="Size" disabled render={() => size} />,
            ]
          ))}
        {record && record.scanset_set.length && (
          <ArrayField label="Scan Sets" source="scanset_set">
            <Datagrid
              style={{ tableLayout: "fixed" }}
              expand={(rec: Record) => (
                <ScanMeasurements
                  scanId={rec.record.id}
                  files={rec.record.files}
                  {...props}
                />
              )}
            >
              <DateField
                source="scandate"
                locales="en-DE"
                options={{
                  hour: "2-digit",
                  minute: "2-digit",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour12: false,
                }}
                showTime
              />
            </Datagrid>
          </ArrayField>
        )}
      </SimpleShowLayout>
    </Show>
  );
};
