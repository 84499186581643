import styled from "@emotion/styled";
import React, { ReactElement } from "react";
import {
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useMutation,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { orderStatusChoices } from "../OrderEdit";

const CustomForm = styled(SimpleForm)({
  display: "inline-flex",
  height: "24px",
  padding: "6px 8px",
  alignItems: "center",
  "&.onefid-save .RaToolbar-desktopToolbar-325": {
    backgroundColor: "inherit",
    marginTop: 0,
  },
});

const OrderEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton style={{ marginTop: 0 }} />
  </Toolbar>
);

type BulkUpdateStatusProps = {
  selectedIds: number[];
  resource: string;
};

export const BulkUpdateStatus = (
  props: BulkUpdateStatusProps
): ReactElement => {
  const { selectedIds, resource } = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [mutate] = useMutation();
  const updateOrders = (formData: { status: string }) => {
    mutate(
      {
        type: "updateMany",
        resource: "orders",
        payload: { ids: selectedIds, data: formData },
      },
      {
        onSuccess: () => {
          refresh();
          notify("Orders updated", "success");
          unselectAll(resource);
        },
        onFailure: () => notify("Error: Orders not updated", "warning"),
      }
    );
  };

  return (
    <CustomForm
      className="onefid-save"
      toolbar={<OrderEditToolbar />}
      save={(formData: { status: string }) => {
        updateOrders(formData);
      }}
    >
      <SelectInput source="status" choices={orderStatusChoices} />
    </CustomForm>
  );
};
