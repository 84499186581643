import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import QRCode from "qrcode.react";
import {
  convertLastWidthCategoryToSteitzWidth,
  displayUserIdentifier,
} from "../../../utils/Helper";
import { OrderApi } from "../../../providers/Interfaces";

const CustomBox = styled(Box)({
  display: "none",
  "@media print": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    height: "215px",
    width: "218px",
    padding: "20px",
    backgroundColor: "lightgrey",
    border: "1px solid white",
    lineHeight: "0.95",
    fontSize: "14px",
  },
});

export const QrPrint = (props: OrderApi): ReactElement => {
  const {
    sha1,
    v,
    t,
    oid,
    olid,
    industry_partner: industryPartner,
    site,
    brand,
    variant,
    eu,
    last_width_category: lastWidthCategory,
  } = props;
  const customer = displayUserIdentifier(props);

  // Indent the keys by two spaces to meet requirements of desma validation
  const stringifiedJson = JSON.stringify(
    {
      sha1,
      v,
      t,
      oid,
      olid,
    },
    null,
    2
  );

  return (
    <CustomBox>
      <Box>
        <QRCode value={stringifiedJson} size={125} renderAs="svg" />
      </Box>
      <br />
      {`Partner: ${industryPartner}`}
      <br />
      {`Site: ${site}`}
      <br />
      {`Customer: ${customer}`}
      <br />
      {`${brand} ${variant}`}
      <br />
      {`Left Size: ${eu}, Right Size ${eu}`}
      <br />
      {`Left Width: ${convertLastWidthCategoryToSteitzWidth(
        lastWidthCategory
      )}, Right Width ${convertLastWidthCategoryToSteitzWidth(
        lastWidthCategory
      )}`}
    </CustomBox>
  );
};
