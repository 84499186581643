// import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  // Filter,
  List,
  Loading,
  TextField,
} from "react-admin";
// import { getMobileMeasurementsStatisticsFromSessionStorage } from "shared/src/utils/storage-variables";
import { ComponentProps } from "../../providers/Interfaces";
import { exportToExcel } from "../../utils/Helper";

const years = [...new Array(new Date().getFullYear() - 2020)].map(
  (_: any, i: number) => (i + 2021).toString()
);

const yearsChoices: Array<{ id: string; name: string }> = [];
years.map((year) => yearsChoices.push({ id: year, name: year }));

// const monthsChoices = [
//   { id: 1, name: "January" },
//   { id: 2, name: "February" },
//   { id: 3, name: "March" },
//   { id: 4, name: "April" },
//   { id: 5, name: "May" },
//   { id: 6, name: "June" },
//   { id: 7, name: "July" },
//   { id: 8, name: "August" },
//   { id: 9, name: "September" },
//   { id: 10, name: "October" },
//   { id: 11, name: "November" },
//   { id: 12, name: "December" },
// ];

// const CustomFilter = styled(Filter)({
//   display: "inline-flex",
//   height: "24px",
//   padding: "6px 8px",
//   alignItems: "center",
//   "&.onefid-save .RaToolbar-desktopToolbar-325": {
//     backgroundColor: "inherit",
//     marginTop: 0,
//   },
// });

// const StatisticsFilters = (props: any) => {
//   const statistics = getMobileMeasurementsStatisticsFromSessionStorage();

//   return (
//     <CustomFilter {...props}>
//       <SelectInput label="Year" source="year" choices={yearsChoices} alwaysOn />
//       <SelectInput
//         label="Month"
//         source="month"
//         choices={monthsChoices}
//         alwaysOn
//       />
//       <FunctionField
//         alwaysOn
//         render={() => `Scans: ${statistics && statistics.scans}`}
//       />
//       <FunctionField
//         alwaysOn
//         render={() =>
//           `Insole Recommendation: ${
//             statistics && statistics.insole_recommendations
//           }`
//         }
//       />
//     </CustomFilter>
//   );
// };

const fields = [
  "id",
  "user",
  "created_at",
  "email",
  "employee_number",
  "industry_partner",
  "store",
  "type",
  "order_nr",
];

export const CartsOverviewList = (props: ComponentProps): JSX.Element => {
  const { permissions } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <List
      {...props}
      // filters={<StatisticsFilters />}
      bulkActionButtons={false}
      exporter={(rec: any) => exportToExcel(rec, fields, "Carts")}
      sort={{ field: "id" }}
      title="Carts"
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField label="Industry Partner" source="industry_partner" />
        <TextField label="Store" source="store" />
        <TextField label="Cart Type" source="type" />
        <DateField
          source="created_at"
          locales="en-DE"
          options={{
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour12: false,
          }}
          showTime
        />
      </Datagrid>
    </List>
  );
};
