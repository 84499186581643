import * as XLSX from "xlsx";
import { OrderApi, UserApi } from "../providers/Interfaces";

export const displayUserIdentifier = (record: UserApi | OrderApi): string => {
  const { employee_number: employeeNumber, email, uuid } = record;
  if (employeeNumber) {
    return employeeNumber;
  }
  if (email) {
    return email;
  }
  if (uuid) {
    return uuid;
  }

  return "";
};

export const convertLastWidthCategoryToSteitzWidth = (
  lwc: number
): string | undefined => {
  const lastWidthCategorySteitzMap: Map<number, "S" | "NB" | "XB" | "XXB"> =
    new Map([
      [1, "S"],
      [2, "S"],
      [3, "NB"],
      [4, "XB"],
      [5, "XXB"],
    ]);

  return lastWidthCategorySteitzMap.get(lwc);
};

export type Dictionary<T> = {
  [key: string]: T;
};

export const nestedDictHasValueForKey = (
  nestedDict: Dictionary<Dictionary<number>>,
  key: string
): boolean => Object.values(nestedDict).some((value) => value[key]);

// split text at - then capitalize string
export const splitAndCapitalize = (str: string): string => {
  return str
    .split("-")
    .map(function capitalize(part) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(" ");
};

export const isValidHttpUrl =
  (message: string | undefined = "Must be a URL") =>
  (str: string): string | false => {
    if (!str) return false;

    let url;
    try {
      url = new URL(str);
    } catch {
      return message;
    }

    if (url.protocol === "http:" || url.protocol === "https:") {
      return false;
    }

    return message;
  };

export const exportToExcel = (
  data: any,
  fields: string[],
  filename: string
) => {
  const filteredData = data.flatMap((item: any) => {
    const personalInfo = item.personal_info
      ? {
          first_name: item.personal_info.first_name,
          last_name: item.personal_info.last_name,
          birth_date: item.personal_info.birth_date,
        }
      : {};

    // Check if cart_items exist
    if (item.cart_items && item.cart_items.length > 0) {
      return item.cart_items.map((cartItem: any) => {
        const filteredItem: { [key: string]: any } = {};

        // Flatten top-level fields
        fields.forEach((field) => {
          if (field in item) {
            filteredItem[field] = item[field];
          }
        });

        // Add cart_items related fields to each row using dot notation
        filteredItem.cart_item_pos_nr = cartItem.pos_nr;
        filteredItem.cart_item_appliances_id = cartItem.appliances_id;
        filteredItem.cart_item_type = cartItem.type;
        filteredItem.cart_item_gtin =
          cartItem.gtin || cartItem.additional_product_gtin;
        filteredItem.cart_item_comments = cartItem.comments;

        // Merge in personal_info fields
        Object.assign(filteredItem, personalInfo);

        return filteredItem;
      });
    }

    // If no cart_items, just flatten top-level fields and personal_info
    const filteredItem: { [key: string]: any } = {};

    fields.forEach((field) => {
      if (field in item) {
        filteredItem[field] = item[field];
      }
    });

    // Merge in personal_info fields
    Object.assign(filteredItem, personalInfo);

    return filteredItem;
  });

  // Convert to Excel sheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write to file
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const exportScansDataToExcel = (
  data: any,
  fields: string[],
  filename: string
) => {
  const filteredData = data.flatMap((item: any) => {
    // Loop over each scanfile
    if (item.scanfiles && item.scanfiles.length > 0) {
      return item.scanfiles.map((scanfile: any) => {
        const filteredItem: { [key: string]: any } = {};

        // Flatten top-level fields
        fields.forEach((field) => {
          if (field in item) {
            filteredItem[field] = item[field];
          }
        });

        // Add scanfiles related fields
        filteredItem.side = scanfile.side;
        filteredItem.condition = scanfile.condition;
        filteredItem.mesh_url = scanfile.mesh_url;
        filteredItem.colored_sole = scanfile.colored_sole;
        filteredItem.last_processed_at = scanfile.last_processed_at;
        filteredItem.status = scanfile.status;
        filteredItem.processed_mesh_url = scanfile.processed_mesh_url;
        filteredItem.plantar_image = scanfile.plantar_image;
        filteredItem.real_color_plantar_image =
          scanfile.real_color_plantar_image;
        filteredItem.foot_type_category = scanfile.foot_type_category;

        if (scanfile.measurements) {
          Object.keys(scanfile.measurements).forEach((key) => {
            const value = scanfile.measurements[key];
            if (typeof value === "number") {
              filteredItem[`${key}`] = value.toFixed(2); // Reduce decimals to two
            } else {
              filteredItem[`${key}`] = value; // Non-numeric values stay the same
            }
          });
        }

        // Flatten categorized_measurements object
        if (scanfile.categorized_measurements) {
          Object.keys(scanfile.categorized_measurements).forEach((key) => {
            const categoryObject = scanfile.categorized_measurements[key];
            filteredItem[`${key}_value`] = categoryObject.value;
            filteredItem[`${key}_category`] = categoryObject.category;
          });
        }

        return filteredItem;
      });
    }

    // If no scanfiles, just return top-level fields
    const filteredItem: { [key: string]: any } = {};
    fields.forEach((field) => {
      if (field in item) {
        filteredItem[field] = item[field];
      }
    });

    return filteredItem;
  });

  // Convert to Excel sheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write to file
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};
