import React, { ReactElement } from "react";
import { Create, SelectInput, SimpleForm } from "react-admin";
import { TextInput } from "../../components/TextInput";
import { UserApi } from "../../providers/Interfaces";
import { safetyClassChoices } from "./UserEdit";

export const CreateUser = (props: UserApi): ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="site" />
        <TextInput source="employee_number" />
        <TextInput source="email" />
        <TextInput source="first_name" />
        <TextInput source="last_name" />
        <SelectInput choices={safetyClassChoices} source="safety_class" />
        <TextInput source="cost_center" />
        <TextInput source="quota" />
      </SimpleForm>
    </Create>
  );
};
