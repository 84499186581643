// this component is the same one as ra-data-drf with the changes
// for UPDATE & UPDATE_MANY to use PATCH instead of PUT

// react-admin with typescript is functioning because of a hack since it has not been migrated to typescript fully
// in the root folder this needs to be added react-admin/index.d.ts
// inside that file you need to add `declare module 'react-admin';`

import { stringify } from "query-string";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "react-admin";
import { setItemInSessionStorage } from "shared/src/utils/storage-variables";

/**
 * @param {object} response - HTTP response from fetch().
 * @param {string} type - React-admin request type, e.g. 'GET_LIST'.
 * @param {object} params - Request parameters. Depends on the request type.
 * @returns {object} Data response.
 */
const convertHttpResponse = (response: any, type: string, params: any) => {
  const { headers, json } = response;

  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      if ("count" in json) {
        if (json.statistics) {
          setItemInSessionStorage(json.statistics, "statistics");
        }

        return { data: json.results, total: json.count };
      }
      if (headers.has("content-range")) {
        return {
          data: json,
          total: Number.parseInt(
            headers.get("content-range").split("/").pop(),
            10
          ),
        };
      }
      if ("detail" in json && json.detail === "Invalid page.") {
        return { data: [], total: 0 };
      }
      throw new Error(
        "The total number of results is unknown. The DRF data provider " +
          "expects responses for lists of resources to contain this " +
          "information to build the pagination. If you're not using the " +
          "default PageNumberPagination class, please include this " +
          'information using the Content-Range header OR a "count" key ' +
          "inside the response."
      );

    case CREATE:
      if (headers.has("Accept-Language")) {
        return {
          headers: headers.set("content-language", "de"),
          data: { ...params.data, id: json.id, headers },
        };
      }

      return {
        data: { ...params.data, id: json.id },
      };
    case DELETE:
      return { data: params.previousData };
    default:
      return { data: json };
  }
};

/**
 * Maps react-admin queries to the default format of Django REST Framework.
 *
 * @param apiUrl - Base URL of API.
 * @param httpClient - Function that accepts base URL and parameters.
 */
export const oneFidDataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson
): unknown => {
  /**
   * @param {string} type - React-admin request type, e.g. 'GET_LIST'.
   * @param {string} resource - Name of the resource to fetch, e.g. 'posts'.
   * @param {object} params - Request parameters. Depends on the request type.
   * @returns {object} { url, options } The HTTP request parameters.
   */

  const convertDataRequestToHttp = (
    type: string,
    resource: string,
    params: any
  ) => {
    let url = "";
    const options: { method?: string; body?: string } = {};
    switch (type) {
      case CREATE:
        url = `${apiUrl}/${resource}/`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        break;
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter } = params;
        const query = {
          page,
          page_size: perPage,
          ordering: `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { filter, target, id } = params;
        const query = {
          page,
          page_size: perPage,
          ordering: `${order === "ASC" ? "" : "-"}${field}`,
          ...filter,
          [target]: id,
        };
        url = `${apiUrl}/${resource}/?${stringify(query)}`;
        break;
      }
      case UPDATE: {
        const { data, previousData } = params;
        const diff = Object.fromEntries(
          Object.entries(data).filter(([key, value]) =>
            // TODO: Consider stringify vaue and previousData[key]
            // to send data only if arrays / objects change
            previousData ? value !== previousData[key] : value
          )
        );

        url = `${apiUrl}/${resource}/${params.id}/`;
        options.method = "PATCH";
        options.body = JSON.stringify(diff);
        break;
      }
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}/`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {string} type - React-admin request type, e.g. 'GET_LIST'.
   * @param {string} resource - Name of the resource to fetch, e.g. 'posts'.
   * @param {object} params - Request parameters. Depends on the request type.
   * @returns {Promise} The Promise for a data response.
   */
  return (type: string, resource: string, params: any) => {
    /**
     * Split GET_MANY, UPDATE_MANY and DELETE_MANY requests into multiple promises,
     * since they're not supported by default.
     */
    switch (type) {
      case GET_MANY:
        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: "GET",
            })
          )
        ).then((responses) => {
          return {
            data: responses.map((response: any) => response.json),
          };
        });
      case UPDATE_MANY: {
        const { data, previousData } = params;
        const diff = Object.fromEntries(
          Object.entries(data).filter(([key, value]) =>
            previousData ? value !== previousData[key] : value
          )
        );

        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: "PATCH",
              body: JSON.stringify(diff),
            })
          )
        ).then((responses) => ({
          data: responses.map((response: any) => response.json),
        }));
      }
      case DELETE_MANY:
        return Promise.all(
          params.ids.map((id: string) =>
            httpClient(`${apiUrl}/${resource}/${id}/`, {
              method: "DELETE",
            })
          )
        ).then((responses) => ({
          data: responses.map((response: any) => response.json),
        }));
      default:
        break;
    }

    const { url, options } = convertDataRequestToHttp(type, resource, params);

    return httpClient(url, options).then((response: any) =>
      convertHttpResponse(response, type, params)
    );
  };
};
