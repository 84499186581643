import React, { ReactElement } from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { DeviceApi } from "../../providers/Interfaces";

export type Props = {
  record?: DeviceApi;
};

const DeviceTitle = (props: Props) => {
  const { record } = props;

  return <span>{`Device ${record && record.device_identifier}`}</span>;
};

export const DeviceShow = (props: Props): ReactElement => {
  return (
    <Show title={<DeviceTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="partner_site.company" label="Company" />
        <TextField source="partner_site.address1" label="Address 1" />
        <TextField source="partner_site.address2" label="Address 2" />
        <TextField source="partner_site.zip_code" label="Zip Code" />
        <TextField source="partner_site.city" label="city" />
        <TextField source="partner_site.country" label="Country" />
        <TextField source="partner_site.long" label="Longitude" />
        <TextField source="partner_site.lat" label="Latitude" />
        <TextField source="partner_site.partner" label="Partner" />
        <TextField source="total_scans" />
        <TextField source="scans_per_day" />
        <TextField source="scans_per_month" />
      </SimpleShowLayout>
    </Show>
  );
};
