/* eslint-disable camelcase */
import React, { ReactElement, useState, ReactNode, useEffect } from "react";
import { Loading, useShowController, useNotify } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getApiService } from "shared/src/utils/api-service";
import { displayUserIdentifier } from "../../utils/Helper";
import { UserApi } from "../../providers/Interfaces";

type ScanSide = {
  id: number;
  side: string;
  plantar_image: string;
  insole_recommendation: string;
  condition: string;
  colored_sole: string;
  measurements: { [key: string]: number };
  raw_measurements: { [key: string]: number };
  categorized_measurements: {
    [key: string]: string | number | { [key: string]: number };
  };
};

type File = {
  id: number;
  side: string;
};

type ButtonWithLoadingSpinnerProps = {
  readonly children?: ReactNode;
  loading: boolean;
  handleClick: () => void;
};

export const ButtonWithLoadingSpinner = ({
  children,
  loading,
  handleClick,
}: ButtonWithLoadingSpinnerProps) => {
  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      {children} &nbsp;
      {loading && <CircularProgress size={20} color="inherit" />}
    </Button>
  );
};

const CustomDisplayUserIdentifier = (rec: UserApi) => {
  return rec.email
    ? displayUserIdentifier(rec).replace(
        /[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~-]/gi,
        "_"
      )
    : displayUserIdentifier(rec);
};

export const ScanMeasurements = ({
  scanId,
  files,
  ...props
}: {
  scanId: number;
  files: File[];
}): ReactElement => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const [isLoading, setIsLoading] = useState(true);
  const [scansetMeasurements, setScansetMeasurements] = useState();
  const [isLoadingMesh, setIsLoadingMesh] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    const getScanMeasurements = async (Id: number) => {
      const scanMeasurements = await getApiService().fetchScanMeasurements(Id);
      setIsLoading(false);
      setScansetMeasurements(scanMeasurements.measurements);
    };

    getScanMeasurements(scanId);
  }, [record, scanId]);

  const handleDownloadClick = async () => {
    setIsLoadingMesh(true);
    const zip = new JSZip();
    const folder = zip.folder("collection");
    Promise.all(
      files.map(async (file: File) => {
        folder.file(
          `${CustomDisplayUserIdentifier(record)}-scan-mesh-${file.side}.stl`,
          await getApiService().fetchScanFile(file.id),
          {
            binary: true,
          }
        );
      })
    )
      .then(() => {
        folder
          .generateAsync({
            type: "blob",
          })
          .then((content: Blob | string) => {
            saveAs(content, `${CustomDisplayUserIdentifier(record)} Scan Mesh`);
            setIsLoadingMesh(false);
          });
      })
      .catch(() => {
        setIsLoadingMesh(false);
        notify("An error occurred, please contact us", "warning");
      });
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Side</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Circumference Ball</TableCell>
              <TableCell>Circumference Instep</TableCell>
              <TableCell>Circumference Preinstep</TableCell>
              <TableCell>Circumference Bend</TableCell>
              <TableCell>Circumference Heel</TableCell>
              <TableCell>Circumference Toe</TableCell>
              <TableCell>Length Foot</TableCell>
              <TableCell>Length Toe</TableCell>
              <TableCell>Length Ball Medial</TableCell>
              <TableCell>Length Ball Lateral</TableCell>
              <TableCell>Length Ball Mid</TableCell>
              <TableCell>Width Ball</TableCell>
              <TableCell>Width Heel</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scansetMeasurements &&
              scansetMeasurements.map((scanSide: ScanSide) => {
                return (
                  <TableRow>
                    <TableCell>{scanSide.side}</TableCell>
                    <TableCell>{scanSide.condition}</TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_ball}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_instep}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_preinstep}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_bend}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_heel}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.circumference_toe}
                    </TableCell>
                    <TableCell>{scanSide.measurements.length_foot}</TableCell>
                    <TableCell>{scanSide.measurements.length_toe}</TableCell>
                    <TableCell>
                      {scanSide.measurements.length_ball_medial}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.length_ball_lateral}
                    </TableCell>
                    <TableCell>
                      {scanSide.measurements.length_ball_mid}
                    </TableCell>
                    <TableCell>{scanSide.measurements.width_ball}</TableCell>
                    <TableCell>{scanSide.measurements.width_heel}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonWithLoadingSpinner
        loading={isLoadingMesh}
        handleClick={() => handleDownloadClick()}
      >
        Download Mesh File
      </ButtonWithLoadingSpinner>
    </>
  );
};
