import styled from "@emotion/styled";
import { Box, Paper, RootRef, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import React, { ReactInstance, useRef } from "react";
import { Button } from "react-admin";
import ReactToPrint from "react-to-print";
import QRCode from "qrcode.react";
import { displayUserIdentifier } from "../../utils/Helper";
import { QrPrint } from "./Components/QrPrint";
import { OrderApi as RecordProp } from "../../providers/Interfaces";

// eslint-disable-next-line react/prefer-stateless-function
class EmptyComponent extends React.Component {
  render() {
    return <></>;
  }
}

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "start",
  marginLeft: "1em",
  minWidth: "33%",
});

const ButtonHiddenOnPrint = styled(Button)({
  "@media print": {
    display: "none",
  },
});

const BoxHiddenOnScreen = styled(Box)({
  "@media screen": {
    display: "none",
  },
});

type Props = {
  record?: RecordProp;
};

const AsideQR = (props: Props) => {
  const emptyElement: ReactInstance = new EmptyComponent({});
  const componentRef1 = useRef<React.Component>(emptyElement);
  const componentRef2 = useRef<React.Component>(emptyElement);
  const { record } = props;
  // Do not render aside component if certain values from the details endpoint are not yet populated.
  if (!record || (!record?.brand && !record?.sha1)) return <></>;
  const {
    industry_partner: industryPartner,
    site,
    brand,
    variant,
    cpn,
    safety_class: safetyClass,
    thumbnail,
    mpn,
    sha1,
    v,
    t,
    oid,
    olid,
    eu,
    gtin,
  } = record;
  const customer = displayUserIdentifier(record);

  // Indent the keys by two spaces to meet requirements of desma validation
  const stringifiedJson = JSON.stringify(
    {
      sha1,
      v,
      t,
      oid,
      olid,
    },
    null,
    2
  );

  return (
    <CustomPaper elevation={2}>
      <RootRef rootRef={componentRef1}>
        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          alignItems="center"
          maxWidth={240}
          margin="1em 2.5em"
        >
          <Typography variant="h6" align="center" gutterBottom>
            Product Information
          </Typography>
          <Typography variant="body2" align="center">
            {industryPartner && `Partner: ${industryPartner}`}
            <br />
            {site && `Site: ${site}`}
            <br />
            {`Customer: ${customer}`}
            <br />
            {`${brand} ${variant}`}
            <br />
            {cpn && `Product Number: ${cpn}`}
            <br />
            {eu && `EU Size: ${eu}`}
            <br />
            {gtin && `GTIN: ${gtin}`}
            <br />
            {safetyClass && `Safety Class: ${safetyClass}`}
            <br />
            {thumbnail && <img src={thumbnail} alt={mpn} width={150} />}
            {sha1 && (
              <>
                <Typography variant="body1" align="center" gutterBottom>
                  Qr Code for Customized Product
                </Typography>
                <Box>
                  <QRCode value={stringifiedJson} size={125} renderAs="svg" />
                </Box>
              </>
            )}
          </Typography>
          <ReactToPrint
            trigger={() => (
              <ButtonHiddenOnPrint label="Print only Qr code">
                <PrintIcon />
              </ButtonHiddenOnPrint>
            )}
            content={() =>
              componentRef2.current ? componentRef2.current : emptyElement
            }
          />
          <ReactToPrint
            trigger={() => (
              <ButtonHiddenOnPrint label="Print all Product Infos">
                <PrintIcon />
              </ButtonHiddenOnPrint>
            )}
            content={() =>
              componentRef1.current ? componentRef1.current : emptyElement
            }
          />
        </Box>
      </RootRef>
      <RootRef rootRef={componentRef2}>
        <BoxHiddenOnScreen
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          margin="30px 0 45px 0"
        >
          <QrPrint {...record} />
        </BoxHiddenOnScreen>
      </RootRef>
    </CustomPaper>
  );
};

// Do not rerender component if record.brand or record.sha1 are overwritten by undefined. For some reason the record is sometimes overwritten by the list endpoint where in contrast to the detail endpoint these keys are not present and values are accordingly undefined.
export const MemoizedAsideQR = React.memo(AsideQR, (prevProps, nextProps) => {
  if (
    (prevProps.record?.brand && !nextProps.record?.brand) ||
    (prevProps.record?.sha1 && !nextProps.record?.sha1)
  )
    return true;

  return false;
});
