import React from "react";
import { Box, Button, Paper, styled, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import serverErrorImage from "../../assets/cloud@2x.png";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0",
  height: "100vh",
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25%",
  height: "45%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px",
  borderRadius: "8px",
  padding: "2%",
  "@media (max-width: 600px)": {
    width: "90%",
    height: "70%",
  },
});

const ErrorImage = styled("img")({
  width: "50%",
  height: "50%",
  marginBottom: "5%",
});

export const ServerErrorPage = () => {
  const history = useHistory();

  const handleRetry = () => {
    history.replace("/");
  };

  return (
    <CustomPaper>
      <CustomBox>
        <ErrorImage src={serverErrorImage} alt="Server Error" />
        <Typography component="h1" variant="h5">
          A server error happened
        </Typography>
        <Typography variant="body1">Please try again later.</Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "5%" }}
          onClick={handleRetry}
        >
          Retry
        </Button>
      </CustomBox>
    </CustomPaper>
  );
};
