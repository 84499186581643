import React from "react";
import { Typography, styled, Paper, Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0",
  height: "100vh",
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25%",
  height: "25%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px",
  borderRadius: "8px",
  padding: "2%",
  "@media (max-width: 600px)": {
    width: "90%",
    height: "70%",
  },
});

const CustomButton = styled(Button)({
  marginTop: "5%",
});

export const PasswordResetTriggerDoneScreen = () => {
  const history = useHistory();

  return (
    <CustomPaper elevation={2}>
      <CustomBox textAlign="center">
        <Typography variant="h5">Password Reset Email Sent</Typography>
        <Typography variant="body1" paragraph>
          If the email exists in our system, you will receive an email from us
          shortly.
        </Typography>
        <Typography variant="body1" paragraph>
          Press the link in the email to reset your account password.
        </Typography>
        <Typography variant="body1">
          If you have any problems, you can reach our customer support.
        </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => history.push("/")}
        >
          Home screen
        </CustomButton>
      </CustomBox>
    </CustomPaper>
  );
};
