import React, { ReactElement } from "react";
import {
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";
import { AsideTabs } from "./AsideTabs";
import { ProductApi } from "../../providers/Interfaces";

export type Props = {
  record?: ProductApi;
};

const ProductTitle = (props: Props) => {
  const { record } = props;

  return <span>{`Product ${record && record.name}`}</span>;
};

export const ProductShow = (props: Props): ReactElement => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;

  return (
    <Show title={<ProductTitle />} aside={<AsideTabs />} {...props}>
      <SimpleShowLayout>
        <TextField source="brand" />
        <TextField source="name" />
        <TextField source="mpn" />
        {record && record.width_name && (
          <TextField source="width_name" label="Width" />
        )}
        <ImageField source="images" src="original" addLabel={false} />
      </SimpleShowLayout>
    </Show>
  );
};
