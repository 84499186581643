import React, { ReactElement, useEffect, useState } from "react";
import {
  DateTimeInput,
  Edit,
  Loading,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
} from "react-admin";
import { CustomerInput } from "../../components/CustomerInput";
import { TextInput } from "../../components/TextInput";
import { Permissions, UserApi } from "../../providers/Interfaces";
import { displayUserIdentifier } from "../../utils/Helper";

export const safetyClassChoices = [
  { id: "OB", name: "OB" },
  { id: "O1", name: "O1" },
  { id: "O2", name: "O2" },
  { id: "O3", name: "O3" },
  { id: "SB", name: "SB" },
  { id: "S1", name: "S1" },
  { id: "S1P", name: "S1P" },
  { id: "S2", name: "S2" },
  { id: "S3", name: "S3" },
];

const UserEditToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const UserTitle = ({ record }: { record: UserApi }) => (
  <span>{`User ${displayUserIdentifier(record)}`}</span>
);

export const UserEdit = ({
  permissions,
  record,
  ...props
}: {
  permissions: Permissions;
  record: UserApi;
}): ReactElement => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <Edit title={<UserTitle record={record} />} undoable={false} {...props}>
      <SimpleForm toolbar={<UserEditToolbar {...props} />}>
        {permissions.includes("partner_has_safety_shoes") && [
          <TextInput source="industry_partner" label="Partner" disabled />,
          <TextInput source="site" />,
        ]}
        <CustomerInput disabled />
        <TextInput source="first_name" disabled />
        <TextInput source="last_name" disabled />
        {permissions.includes("partner_has_safety_shoes") && [
          <SelectInput source="safety_class" choices={safetyClassChoices} />,
          <TextInput source="cost_center" />,
          <TextInput source="quota" />,
        ]}
        <DateTimeInput source="created_at" disabled />
      </SimpleForm>
    </Edit>
  );
};
