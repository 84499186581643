// import Button from '@material-ui/core/Button';
import React, { ReactElement } from "react";
import { Datagrid, List, Responsive, SimpleList, TextField } from "react-admin";
import { getPartnerNameFromSessionStorage } from "shared/src/utils/storage-variables";
import { ComponentProps, DeviceApi } from "../../providers/Interfaces";

export const DevicesList = (props: ComponentProps): ReactElement => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      title="Devices"
      filter={{ partner: getPartnerNameFromSessionStorage() }}
    >
      <Responsive
        medium={
          <Datagrid rowClick="show">
            <TextField source="device_identifier" />
            <TextField source="device_type.name" label="Device Type" />
            <TextField source="device_type.type" label="Measure" />
            <TextField source="store" />
            <TextField source="partner_site.name" label="Partner Name" />
          </Datagrid>
        }
        small={
          <SimpleList
            primaryText={(record: DeviceApi) => record.id}
            secondaryText={(record: DeviceApi) => record.device_identifier}
            tertiaryText={(record: DeviceApi) => record.store}
          />
        }
      />
    </List>
  );
};
