import React, { ReactElement, useEffect, useState } from "react";
import { Edit, Loading, SimpleForm, regex } from "react-admin";
import { TextInput } from "../../components/TextInput";
import { Permissions, UserApi } from "../../providers/Interfaces";

export const PartnerEdit = ({
  permissions,
  record,
  ...props
}: {
  permissions: Permissions;
  record: UserApi;
}): ReactElement => {
  const [loading, setLoading] = useState(true);
  const urlRegex =
    /^(https?:\/\/)([\dA-Za-z-]+\.)+[A-Za-z]{2,}(\.[A-Za-z]{2,})?$/;
  const validateUrl = regex(
    urlRegex,
    "Must be a valid URL starting with http:// or https:// like https://www.example.com or https://www.sub.example.com"
  );

  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <Edit undoable={false} {...props}>
      <SimpleForm>
        <TextInput source="website_url" label="Domain" validate={validateUrl} />
      </SimpleForm>
    </Edit>
  );
};
