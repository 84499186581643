import React, { useRef } from "react";
import { Button, CircularProgress } from "@material-ui/core";

interface FileUploadButtonProps {
  onFileUpload: (file: any) => void;
  loading: boolean;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  onFileUpload,
  loading,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      onFileUpload(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {loading ? (
        <CircularProgress size={30} color="inherit" />
      ) : (
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Upload Orders
        </Button>
      )}
    </div>
  );
};
