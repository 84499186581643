import React, { ReactElement } from "react";
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  FunctionField,
} from "react-admin";
import { Grid } from "@material-ui/core";
import styled from "@emotion/styled";
import { Permissions } from "../../providers/Interfaces";
import { noImage } from "../../assets";

export type FavouriteShoe = {
  [key: string]: string;

  brand: string;
  category: string;
  size: string;
};

const changeDecimalSign = (value: string) => {
  // Convert the value to a string
  const strValue = String(value);

  // Replace "." with ","
  const newValue = strValue.replace(/\./g, ",");

  return newValue;
};

const CustomImageField = styled(ImageField)({
  width: "60%",
  height: "60%",
});

const CustomImage = styled.img({
  width: "60%",
  marginBottom: 10,
});

const calculateNavicularBulgeIndex = (navicularValue: number) => {
  if (navicularValue < -5) return -2;
  if (navicularValue < -3) return -1;
  if (navicularValue <= 3) return 0;
  if (navicularValue <= 5) return 1;

  return 2;
};

const calculateAdductionIndex = (medialValue: number, lateralValue: number) => {
  if (medialValue <= 0) return 2;
  if (lateralValue <= 0) return -2;
  if (medialValue / lateralValue < 0.3) return 1;
  if (medialValue / lateralValue <= 3) return 0;

  return -1;
};

const calculateArchHeightIndex = (archValue: number) => {
  if (archValue < 0.295) return 2;
  if (archValue < 0.325) return 1;
  if (archValue < 0.355) return 0;
  if (archValue < 0.385) return -1;

  return -2;
};

let archIndex: number;
let adductionIndex: number;
let navicularIndex: number;

const calculateInsoleCategory = (record: any, side: string) => {
  if (side === "left" && record.arch_height_index_left) {
    archIndex = calculateArchHeightIndex(record.arch_height_index_left);
    adductionIndex = calculateAdductionIndex(
      record.medial_distance_left,
      record.lateral_distance_left
    );
    navicularIndex = calculateNavicularBulgeIndex(
      record.navicular_bulge_distance_left
    );

    const fpi = navicularIndex + adductionIndex + archIndex;

    if (fpi === 0) return "Neutral";
    if (fpi === 4) return "Neutral";
    if (fpi <= -1) return "High";
    if (fpi < 4) return "Neutral";

    return "Low";
  }
  if (side === "right" && record.arch_height_index_right) {
    archIndex = calculateArchHeightIndex(record.arch_height_index_right);
    adductionIndex = calculateAdductionIndex(
      record.medial_distance_right,
      record.lateral_distance_right
    );
    navicularIndex = calculateNavicularBulgeIndex(
      record.navicular_bulge_distance_right
    );

    const fpi = navicularIndex + adductionIndex + archIndex;

    if (fpi === 0) return "Neutral";
    if (fpi === 4) return "Neutral";
    if (fpi <= -1) return "High";
    if (fpi < 4) return "Neutral";

    return "Low";
  }

  return "-";
};

const fieldsToShow = [
  { label: "Medial ball length", property: "medial_ball_length" },
  { label: "Medial distance", property: "medial_distance" },
  { label: "Navicular bulge distance", property: "navicular_bulge_distance" },
  { label: "Foot length", property: "foot_length_manual" },
  { label: "Lateral distance", property: "lateral_distance" },
  { label: "Dorsum height", property: "dorsum_height" },
  { label: "Arch height index", property: "arch_height_index" },
  { label: "Ball width", property: "ball_width_manual" },
];

export const MobileMeasurementsShow = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}): ReactElement => {
  return (
    <Show title="Measurements" {...props}>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <SimpleShowLayout>
            <TextField source="id" />
            <DateField
              source="created_at"
              locales="en-DE"
              options={{
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour12: false,
              }}
              showTime
            />
            <TextField source="app_version" />
            <TextField source="operating_system" />
            <TextField source="region" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6} lg={4}>
          <SimpleShowLayout>
            <h4>Left Foot</h4>
            {fieldsToShow.map((field) => (
              <FunctionField
                key={field}
                label={field.label}
                render={(rec: any) => (
                  <p>{changeDecimalSign(rec[`${field.property}_left`])}</p>
                )}
              />
            ))}
            <FunctionField
              label="Insole category"
              render={(rec: any) => (
                <p>{calculateInsoleCategory(rec, "left")}</p>
              )}
            />
            <FunctionField
              label="Left dorsal image"
              render={(rec: any) =>
                rec.measure_dorsal_foot_debug_image_left ? (
                  <ImageField
                    source="measure_dorsal_foot_debug_image_left"
                    sx={{
                      "& img": {
                        maxWidth: 500,
                        maxHeight: 500,
                        objectFit: "contain",
                      },
                    }}
                  />
                ) : (
                  <div>
                    <CustomImage src={noImage} />
                  </div>
                )
              }
            />
            <FunctionField
              label="Left medial image"
              render={(rec: any) =>
                rec.measure_medial_foot_debug_image_left ? (
                  <CustomImageField
                    source="measure_medial_foot_debug_image_left"
                    src="original"
                  />
                ) : (
                  <div>
                    <CustomImage src={noImage} />
                  </div>
                )
              }
            />
            <FunctionField
              label="Left dorsal original image"
              render={(rec: any) => {
                let imageComponent;

                if (rec.input_image_dorsal_left) {
                  imageComponent = (
                    <CustomImageField
                      source="input_image_dorsal_left"
                      src="original"
                    />
                  );
                } else if (rec.input_image_dorsal_both) {
                  imageComponent = (
                    <CustomImageField
                      source="input_image_dorsal_both"
                      src="original"
                    />
                  );
                } else {
                  imageComponent = (
                    <div>
                      <CustomImage src={noImage} />
                    </div>
                  );
                }

                return imageComponent;
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6} lg={4}>
          <SimpleShowLayout>
            <h4>Right Foot</h4>
            {fieldsToShow.map((field) => (
              <FunctionField
                key={field}
                label={field.label}
                render={(rec: any) => (
                  <p>{changeDecimalSign(rec[`${field.property}_right`])}</p>
                )}
              />
            ))}
            <FunctionField
              label="Insole category"
              render={(rec: any) => (
                <p>{calculateInsoleCategory(rec, "right")}</p>
              )}
            />
            <FunctionField
              label="Right dorsal image"
              render={(rec: any) =>
                rec.measure_dorsal_foot_debug_image_right ? (
                  <CustomImageField
                    source="measure_dorsal_foot_debug_image_right"
                    src="original"
                  />
                ) : (
                  <div>
                    <CustomImage src={noImage} />
                  </div>
                )
              }
            />
            <FunctionField
              label="Right medial image"
              render={(rec: any) =>
                rec.measure_medial_foot_debug_image_right ? (
                  <CustomImageField
                    source="measure_medial_foot_debug_image_right"
                    src="original"
                  />
                ) : (
                  <div>
                    <CustomImage src={noImage} />
                  </div>
                )
              }
            />
            <FunctionField
              label="Right dorsal original image"
              render={(rec: any) => {
                let imageComponent;

                if (rec.input_image_dorsal_right) {
                  imageComponent = (
                    <CustomImageField
                      source="input_image_dorsal_right"
                      src="original"
                    />
                  );
                } else if (rec.input_image_dorsal_both) {
                  imageComponent = (
                    <CustomImageField
                      source="input_image_dorsal_both"
                      src="original"
                    />
                  );
                } else {
                  imageComponent = (
                    <div>
                      <CustomImage src={noImage} />
                    </div>
                  );
                }

                return imageComponent;
              }}
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};
