import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Box,
  Button,
  TextField,
  styled,
  CircularProgress,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { getApiService } from "shared/src/utils/api-service";

const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0",
  height: "100vh",
});

const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "25%",
  height: "25%",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  border: "1px",
  borderRadius: "8px",
  padding: "2%",
  "@media (max-width: 600px)": {
    width: "90%",
    height: "70%",
  },
});

const CustomButton = styled(Button)({
  marginTop: "5%",
});

export const PasswordResetConfirmScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [signature, setSignature] = useState("");
  const [userId, setUserId] = useState("");
  const [timestamp, setTimeStamp] = useState("");
  const [initialRegister, setInitialRegister] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordIsWeak, setPasswordIsWeak] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const parseUrl = () => {
      const { search } = location;
      if (search) {
        return new URLSearchParams(search);
      }

      console.error("Error: Could not parse iframe url");

      return null;
    };

    const configurePlugin = (params: any) => {
      let partnerId = params.get("partner");
      if (!partnerId) {
        partnerId = "6";
      }
    };

    const params = parseUrl();
    if (!params) {
      history.replace("/");

      return;
    }

    configurePlugin(params);

    const uid = params.get("user_id");
    const tst = params.get("timestamp");
    const sgn = params.get("signature");
    const iniReg = params.get("initial_register") === "true";
    if (!uid || !tst || !sgn) {
      console.error("Error: Could not parse activation signature");
      history.replace("/server-error");

      return;
    }
    setUserId(uid);
    setTimeStamp(tst);
    setSignature(sgn);
    if (iniReg) setInitialRegister(true);

    setIsLoading(false);
  }, [history, location]);

  const checkPasswordStrength = () => {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[\d!#$%&*?@A-Za-z]{8,}$/;
    if (passwordPattern.test(password)) {
      setPasswordIsWeak(false);
    } else {
      setPasswordIsWeak(true);
    }
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
    checkPasswordStrength();
  };

  const checkPasswordMatch = (pass: any, confirm: any) => {
    if (pass !== confirm) {
      setPasswordMatchError("Passwords do not match");
    } else {
      setPasswordMatchError("");
    }
  };

  const handlePasswordConfirmChange = (event: any) => {
    setPasswordConfirm(event.target.value);
    checkPasswordMatch(password, event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      await (initialRegister
        ? getApiService().postSetInitialPasswordAndActivate(
            { userId, signature, timestamp },
            password
          )
        : getApiService().postResetPassword(
            { userId, signature, timestamp },
            password
          ));
      history.push("/password-reset-complete");
    } catch {
      history.replace("/server-error");
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <CustomPaper elevation={2}>
      <CustomBox textAlign="center">
        <Typography variant="h5">
          {initialRegister ? "Set Password" : "Reset Password"}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            required
            id="passwordConfirm"
            label="Password Confirm"
            type="password"
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
            error={passwordMatchError !== ""}
            helperText={passwordMatchError}
          />
          {passwordIsWeak && (
            <Typography style={{ color: "red", fontSize: "14px" }}>
              The password needs at least 8 characters, alphanumeric characters.
            </Typography>
          )}
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Confirm
          </CustomButton>
        </form>
      </CustomBox>
    </CustomPaper>
  );
};
