import React, { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  downloadCSV,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  Loading,
  Responsive,
  ShowButton,
  SimpleList,
  TextField,
  useListController,
} from "react-admin";
import { getApiService } from "shared/src/utils/api-service";
import { TextInput } from "../../components/TextInput";
import {
  ComponentProps,
  Permissions,
  UserApi,
} from "../../providers/Interfaces";
import {
  displayUserIdentifier,
  exportToExcel,
  nestedDictHasValueForKey,
} from "../../utils/Helper";

const fields = [
  "email",
  "employee_number",
  "ball_width_manual",
  "foot_length_manual",
  "ball_width_manual_left",
  "foot_length_manual_left",
  "ball_width_manual_right",
  "foot_length_manual_right",
  "safety_class",
  "cost_center",
  "quota",
  "quota_remaining",
  "number_of_orders",
  "device",
  "device_site",
  "created_at",
];

const UsersMeasurmentsExporter = () => {
  getApiService()
    .exportUsersMeasurements()
    .then((response: string) => downloadCSV(response, "user measurements"));
};

const Actions = () => {
  return (
    <div>
      <ExportButton
        label="Export user list"
        exporter={(rec: any) => exportToExcel(rec, fields, "Users")}
      />
      <ExportButton
        label="Export user measurements"
        exporter={UsersMeasurmentsExporter}
      />
    </div>
  );
};

const UsersFilter = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}) => (
  <Filter {...props}>
    <TextInput source="search" label="Customer" alwaysOn resettable />
    {permissions.includes("partner_has_safety_shoes") && (
      <TextInput source="industry_partner" label="Partner" resettable />
    )}
    {permissions.includes("partner_has_safety_shoes") && (
      <TextInput source="site" resettable />
    )}
  </Filter>
);

export const UsersList = (props: ComponentProps): JSX.Element => {
  const { permissions, hasEdit } = props;
  const controllerProps = useListController(props);
  const { data } = controllerProps;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (permissions !== undefined) {
      setLoading(false);
    }
  }, [permissions]);

  if (loading) return <Loading />;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<UsersFilter {...props} />}
      actions={<Actions />}
      sort={{ field: "employee_number" }}
    >
      <Responsive
        medium={
          <Datagrid rowClick={() => (hasEdit ? "edit" : "show")}>
            {permissions.includes("partner_has_safety_shoes") && [
              <TextField source="industry_partner" label="Partner" />,
              <TextField source="site" />,
            ]}
            <FunctionField
              label="Customer"
              render={(record: UserApi) => displayUserIdentifier(record)}
            />
            {nestedDictHasValueForKey(data, "foot_length_manual") && (
              <TextField label="Foot length" source="foot_length_manual" />
            )}
            {nestedDictHasValueForKey(data, "ball_width_manual") && (
              <TextField label="Ball width" source="ball_width_manual" />
            )}
            {permissions.includes("partner_has_safety_shoes") && [
              <TextField source="safety_class" />,
              <TextField source="cost_center" />,
              <TextField source="quota" />,
              <TextField source="quota_remaining" />,
              <TextField source="number_of_orders" />,
            ]}
            {hasEdit ? (
              <EditButton style={{ float: "right" }} />
            ) : (
              <ShowButton style={{ float: "right" }} />
            )}
            <DateField
              source="created_at"
              locales="en-DE"
              options={{
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour12: false,
              }}
              showTime
            />
          </Datagrid>
        }
        small={
          <SimpleList
            primaryText={(record: UserApi) => displayUserIdentifier(record)}
            secondaryText={(record: UserApi) => record.safety_class}
            tertiaryText={(record: UserApi) => record.number_of_orders}
          />
        }
      />
    </List>
  );
};
