import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AdminView } from "./pages/AdminView";
import { PasswordResetScreen } from "./pages/Login/ResetPassword";
import { PasswordResetTriggerDoneScreen } from "./pages/Login/PasswordResetTriggerDone";
import { PasswordResetCompleteScreen } from "./pages/Login/PasswordResetCompleteScreen";
import { PasswordResetConfirmScreen } from "./pages/Login/PasswordResetConfirmScreen";
import { ServerErrorPage } from "./pages/Login/ServerError";

export const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={AdminView} />
        <Route path="/password-reset" component={PasswordResetScreen} />
        <Route
          path="/password-reset-done"
          component={PasswordResetTriggerDoneScreen}
        />
        <Route
          path="/password-reset-confirm"
          component={PasswordResetConfirmScreen}
        />
        <Route
          path="/password-reset-complete"
          component={PasswordResetCompleteScreen}
        />
        <Route path="/server-error" component={ServerErrorPage} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};
