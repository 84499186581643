import React, { ReactElement, useState } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  useShowController,
  useNotify,
  NumberField,
} from "react-admin";
import JSZip from "jszip";
import { getApiService } from "shared/src/utils/api-service";
import { Permissions } from "../../providers/Interfaces";
import { ButtonWithLoadingSpinner } from "../Users/ScanMeasurements";

export const ScansOverviewShow = ({
  permissions,
  ...props
}: {
  permissions: Permissions;
}): ReactElement => {
  const controllerProps = useShowController(props);
  const [isLoadingMesh, setIsLoadingMesh] = useState(false);
  const { record } = controllerProps;
  const notify = useNotify();

  const handleDownloadClick = async () => {
    setIsLoadingMesh(true);
    const zip = new JSZip();
    const folder = zip.folder("collection");
    Promise.all(
      record.scanfiles.map(async (file: any) => {
        folder.file(
          `${record.id}-scan-mesh-${file.side}.stl`,
          getApiService().fetchScanFileWithUrl(file.processed_mesh_url),
          {
            binary: true,
          }
        );
      })
    )
      .then(() => {
        folder
          .generateAsync({
            type: "blob",
          })
          .then((content: Blob | string) => {
            saveAs(content, `${record.id} Scan Mesh`);
            setIsLoadingMesh(false);
          });
      })
      .catch(() => {
        setIsLoadingMesh(false);
        notify("An error occurred, please contact us", "warning");
      });
  };

  return (
    <Show {...props} resource="scans">
      <SimpleShowLayout>
        <ArrayField source="scanfiles">
          <Datagrid>
            <TextField source="side" label="Side" />
            <TextField source="condition" label="Condition" />
            <TextField source="foot_type_category" label="Foot type category" />
            <TextField
              source="categorized_measurements.arch_height.category"
              label="Arch Height"
            />
            <TextField
              source="categorized_measurements.arch_height_index.category"
              label="Arch Height Index"
            />
            <TextField
              source="categorized_measurements.arch_index_plantar.category"
              label="Arch Index Plantar"
            />
            <TextField
              source="categorized_measurements.arch_index_mesh.category"
              label="Arch Index Mesh"
            />
            <TextField
              source="categorized_measurements.heel_angle.category"
              label="Heel Angle"
            />
            <TextField
              source="categorized_measurements.heel_angle_tendon.category"
              label="Heel Angle Tendon"
            />
            <NumberField
              options={{
                minimumFractionDigits: 1,
              }}
              source="measurements.width_ball"
              label="Foot Width"
            />
            <NumberField
              options={{
                minimumFractionDigits: 1,
              }}
              source="measurements.length_foot"
              label="Foot Length"
            />
          </Datagrid>
        </ArrayField>
        <ButtonWithLoadingSpinner
          loading={isLoadingMesh}
          handleClick={() => handleDownloadClick()}
        >
          Download Mesh File
        </ButtonWithLoadingSpinner>
      </SimpleShowLayout>
    </Show>
  );
};
